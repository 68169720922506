import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Location, Redirect, Router, navigate } from "@reach/router";
import AddOns from "Components/Organisms/AddOns";
import { CROCats } from "Components/Organisms/CRO/Cats";
import Page404 from "Components/Organisms/404";
import ScrollToTop from "Components/Atoms/ScrollToTop";
import CheckoutError from "Components/Organisms/CheckoutError";
import ThankYou from "Components/Organisms/ThankYou";
import { useExitIntent } from "Hooks/useExitIntent";
import { DISALLOWED_EXIT_INTENT_ROUTES } from "Utils/exit-intent";
import { useOverlayTriggerState } from "react-stately";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "Hooks/useMediaQuery";
import ExitIntentModal from "./Components/Organisms/ExitIntent/ExitIntentModal";
import LocationListener from "./Components/LocationListener";
import PreCheckout from "./Components/Organisms/PreCheckout";
import Checkout from "Components/Organisms/CheckOut";
import { Names } from "Components/Organisms/CRO/Names";
import { Food } from "Components/Organisms/CRO/Food";
import { Proteins } from "Components/Organisms/CRO/Proteins";
import { CROTextures } from "Components/Organisms/CRO/Textures";
import { CatIntro } from "Components/Organisms/CRO/Cat";
import { CatGender } from "Components/Organisms/CRO/Gender";
import { CatBreed } from "Components/Organisms/CRO/Breed";
import { CatAge } from "Components/Organisms/CRO/Age";
import { CatSterilization } from "Components/Organisms/CRO/Sterilization";
import { CROCatBuild } from "Components/Organisms/CRO/CatBuild";
import { CatPickiness } from "Components/Organisms/CRO/Pickiness";
import { CatHealth } from "Components/Organisms/CRO/Health";
import { Benefits } from "Components/Organisms/CRO/Benefits";
import { Sampler } from "Components/Organisms/CRO/Sampler";
import { CROIntro } from "Components/Organisms/CRO/Intro";
import { useExperiments } from "@smalls/helpers";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

function App(): JSX.Element {
	navigate("https://www.smalls.com/start");

	const state = useOverlayTriggerState({});
	const isMobile = useMediaQuery("(max-width: 32rem)");

	useExperiments();

	useEffect(() => {
		const handleVisibility = () => {
			state.open();
		};

		if (isMobile) {
			document.addEventListener("visibilitychange", handleVisibility);
		}

		return () =>
			document.removeEventListener("visibilitychange", handleVisibility);
	}, [isMobile, state]);

	useExitIntent({
		threshold: 20,
		onExitIntent: () => {
			if (document.cookie.indexOf("exit_modal_seen=true") < 0) {
				state.open();
			}
		},
	});

	// TODO: test redirects

	return (
		<HelmetProvider>
			<Helmet>
				<title>Get started &ndash; Smalls</title>
			</Helmet>
			<Location>
				{({ location }) => {
					return (
						<>
							<ScrollToTop pathname={location.pathname} />
							<LocationListener>
								<QueryClientProvider client={queryClient}>
									<Router location={location}>
									<CROIntro path="/" />
										<CROCats path="cats" />
										{/*  */}
										<Redirect from="cats-names" to="/cats" noThrow />

										<Names path="names" />
										<Food path="food" />
										{/*  */}
										<Redirect from="cat/age-specific" to="/cats" noThrow />
										<Redirect from="weight-specific" to="/cats" noThrow />

										<Proteins path="proteins" />
										{/*  */}
										<Redirect
											from="dietary-restrictions"
											to="/cats"
											noThrow
										/>

										<CROTextures path="textures" />
										<CatIntro path="cat/:catId" />
										<CatGender path="cat/:catId/gender" />
										<CatBreed path="cat/:catId/breed" />
										<CatAge path="cat/:catId/age" />
										<CatSterilization path="cat/:catId/sterilization" />
										<CROCatBuild path="cat/:catId/build" />
										<CatPickiness path="cat/:catId/pickiness" />
										<CatHealth path="cat/:catId/health" />
										<Benefits path="benefits" />
										<Sampler path="sampler" />
										{/*  */}
										<Redirect from="plans" to="/sampler" noThrow />

										<AddOns path="add-ons" />
										<PreCheckout path="precheckout" />
										<Checkout path="checkout" />
										<CheckoutError path="checkout/error" />
										<ThankYou path="confirm-payment/thank-you" />
										<Page404 default />
									</Router>
								</QueryClientProvider>
							</LocationListener>
							{state.isOpen &&
								!DISALLOWED_EXIT_INTENT_ROUTES.includes(location.pathname) && (
									<ExitIntentModal state={state} />
								)}
						</>
					);
				}}
			</Location>
		</HelmetProvider>
	);
}

export default App;
